import React, { createContext, useContext, useState } from 'react'

export type UserType = {
    id: string,
    email_address: string,
    phone_number: string|null,
    first_name: string,
    last_name: string,
    image_url: string
}

export type SessionType = {
    isAuthenticated: boolean,
    user: UserType | null
}


interface IUserSessionType {
    session: SessionType,
    setSession: Function
    cartCount: number,
    setCartCount: (count: number) => void,
    favCount: number,
    setFavCount: (count: number) => void,
}

export const SessionContext = createContext<IUserSessionType>({
    session: {
        isAuthenticated: false,
        user: null
    },
    setSession: () => {},
    cartCount: 0,
    setCartCount: () => {},
    favCount: 0,
    setFavCount: () => {},
});

export default function SessionContextProvider(props: any) {
    const { value, children } = props;
    const { session, setSession } = value;
    const [cartCount, setCartCount] = useState(0);
    const [favCount, setFavCount] = useState(0);

    return (
        <SessionContext.Provider
        value={{
            session,
            setSession,
            cartCount,
            setCartCount,
            favCount,
            setFavCount,
        }}
    >
            {children}
        </SessionContext.Provider>
    );
}

export const useSessionContext = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error(
            "useSessionContext must be unsed within a SessionContextProvider"
        );
    };
    return context;
}

