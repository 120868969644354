import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import '../../../styles/Track.scss';
import PageLoading from 'Pages/layout/PageLoading';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';

interface Log {
  status: string;
  created_at: string;
}

interface Delivery {
  order_id: string;
  order_reference: string;
  order_status: string;
  shipping_status: string;
  shipping_share_link: string;
  shipping_delivery_code: string;
  shipping_driver_name: string | null;
  shipping_driver_phone: string | null;
  shipping_driver_plate_number: string | null;
  logs: Log[];
}

const Track: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const [delivery, setDelivery] = useState<Delivery | null>(null);
  const [showAllUpdates, setShowAllUpdates] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDelivery = async () => {
      const token = getTokenOrDefault();
      setIsLoading(true);
      try {
        const response = await tryFetch({
          url: `/g/orders/${orderId}/track`,
          method: 'GET',
          token,
        });

        if (response.is_success && response.data) {
          setDelivery(response.data as Delivery);
        } else {
          console.error('Failed to fetch delivery:', response.errors || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching delivery:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDelivery();
  }, [orderId]);

  if (isLoading) {
    return <PageLoading />;
  }

  if (!delivery) {
    return <div>No delivery details available for the given order ID.</div>;
  }

  const displayedUpdates = showAllUpdates ? delivery?.logs || [] : delivery?.logs?.slice(0, 3) || [];

  return (
    <div className="track-page">
      <IoArrowBackCircle
        className="track-back-button"
        size={36}
        onClick={() => navigate(-1)}
      />

      <div className="track-header">
        <div className="track-title">Track Order</div>
        <div className="order-id">{delivery.order_reference}</div>
      </div>


      <div className="delivery-details">
        <div className="section-title">Delivery Details</div>
        <div className="text">
          No.: <i>{delivery.shipping_delivery_code}</i>
        </div>
        <div className="text">Order Status: {delivery.order_status?.replace(/_/g, ' ') || 'N/A'}</div>
<div className="text">Shipping Status: {delivery.shipping_status?.replace(/_/g, ' ') || 'N/A'}</div>
      </div>

  
      <div className="driver-info">
        <div className="section-title">Carrier</div>
        <div className="text">
          Delivery Driver: {delivery.shipping_driver_name || 'N/A'}
        </div>
        <div className="text">
          Contact Number:{' '}
          {delivery.shipping_driver_phone ? (
            <a href={`tel:${delivery.shipping_driver_phone}`}>{delivery.shipping_driver_phone}</a>
          ) : (
            'N/A'
          )}
        </div>
        <div className="text">Plate Number: {delivery.shipping_driver_plate_number || 'N/A'}</div>
      </div>


      {delivery.shipping_share_link && (
        <div className="tracking-iframe-container">
          <iframe
            src={delivery.shipping_share_link}
            title="Live Tracking"
            frameBorder="0"
            style={{
              width: '100%',
              height: '500px',
              borderRadius: '8px',
              marginTop: '20px',
            }}
          />
        </div>
      )}

      <div className="tracking-timeline card">
        {displayedUpdates.map((update, index) => (
          <div key={index} className="timeline-entry">
            <div className="timeline-time">
              {new Date(update.created_at).toLocaleString()}
            </div>
            <div className="timeline-status">{update.status.replace(/_/g, ' ')}</div>
          </div>
        ))}
        {(delivery.logs?.length ?? 0) > 3 && (
          <button
            onClick={() => setShowAllUpdates(!showAllUpdates)}
            className="show-more-btn"
          >
            {showAllUpdates ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Track;