import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../../styles/Home.scss';
import { tryFetch } from '../../../Helpers/FetchAPI';

interface Merchant {
  id: string;
  merchant_name: string;
  category: string;
  hero_url: string;
  logo_url?: string;
  status: string;
  featured?: { title: string; mime_type: string; url: string; source: string }[] | null; 
}

interface DefaultImage {
  id: string;
  type: string;
  image_url: string;
}

const MerchantSection: React.FC = () => {
  const [groupedMerchants, setGroupedMerchants] = useState<Record<string, Merchant[]>>({});
  const [isGridView, setIsGridView] = useState<Record<string, boolean>>({});
  const [shouldShowSeeAll, setShouldShowSeeAll] = useState<Record<string, boolean>>({});
  const [loadedImages, setLoadedImages] = useState<Record<string, boolean>>({});
  const merchantRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const [defaultProfileImg, setDefaultProfileImg] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDefaultProfileImg = async () => {
      try {
        const response = await fetch('/assets/default_img.json');
        if (!response.ok) {
          throw new Error('Failed to fetch default profile image');
        }
        const data: DefaultImage[] = await response.json();
        const defaultImage = data.find((img) => img.type === 'default profile');
        setDefaultProfileImg(defaultImage?.image_url || '');
      } catch (error) {
        console.error('Error fetching default profile image:', error);
      }
    };

    const fetchMerchants = async () => {
      try {
        const response = await tryFetch({
          url: '/merchants',
          method: 'GET',
        });

        if (!response.is_success) {
          throw new Error(response.error || 'Failed to fetch merchants');
        }

        const merchants: Merchant[] = (response.data as any) || [];

        const grouped = merchants.reduce((acc: Record<string, Merchant[]>, merchant: Merchant) => {
          if (!acc[merchant.category]) acc[merchant.category] = [];
          acc[merchant.category].push(merchant);
          return acc;
        }, {});

        setGroupedMerchants(grouped);
        updateVisibility(grouped);
      } catch (error) {
        console.error('Error fetching merchants:', error);
      }
    };

    const updateVisibility = (grouped: Record<string, Merchant[]>) => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;

      const visibility = Object.keys(grouped).reduce((acc: Record<string, boolean>, category) => {
        const threshold = isMobile ? 2 : 4; 
        acc[category] = grouped[category].length > threshold;
        return acc;
      }, {});

      setShouldShowSeeAll(visibility);
    };

    fetchDefaultProfileImg();
    fetchMerchants();

    const handleResize = () => {
      updateVisibility(groupedMerchants);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageLoad = (key: string) => {
    setLoadedImages((prev) => ({ ...prev, [key]: true }));
  };

  const toggleViewOrNavigate = (category: string, merchantCount: number) => {
    if (merchantCount > 10) {
      navigate(`/category/${category}`);
    } else {
      setIsGridView((prev) => ({ ...prev, [category]: !prev[category] }));
    }
  };

  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <div className="merchant-sections">
      {Object.entries(groupedMerchants).filter(([category]) => category !== 'Others')
      .map(([category, merchants]) => (
        <div key={category} className="merchant-category">
          <div className="category-header">
            <h3>{category}</h3>
            {shouldShowSeeAll[category] && (
              <button
                className="see-all"
                onClick={() => toggleViewOrNavigate(category, merchants.length)}
              >
                {isGridView[category] ? 'Back to Slide' : 'See All'}
              </button>
            )}
          </div>
          <div
            className={`merchant-list ${
              isGridView[category] ? 'grid-view' : 'scrollable'
            }`}
            ref={(el) => (merchantRefs.current[category] = el)}
          >
            {merchants.map((merchant: Merchant) => (
              <div key={merchant.id} className="merchant-item">
                <Link to={`/merchant/${merchant.id}`}>
                <div className="merchant-image-wrapper">
                  <img
                    src={merchant.logo_url || defaultProfileImg}
                    alt={merchant.merchant_name}
                    className={`merchant-image ${
                      loadedImages[`merchant-${merchant.id}`] ? 'loaded' : ''
                    }`}
                    onLoad={() => handleImageLoad(`merchant-${merchant.id}`)}
                  />
                   </div>
                  <p className="merchant-name">
                    {truncateText(merchant.merchant_name, 12)}
                  </p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
      {groupedMerchants['Others'] && (
        <div className="others-button">
          <button
            className="and-more"
            onClick={() => navigate('/others')}
          >
            And More
          </button>
        </div>
      )}
    </div>
  );
};

export default MerchantSection;
