import React from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAuthenticate } from './hooks/useAuthenticate';
import '../../styles/SignIn.scss';
import { getEnv } from '../../Helpers/Env';

const SignIn: React.FC = () => {
	const { formik, recaptcha, loading, errorMessage } = useAuthenticate();

	return (
		<div className="signin-container">
			<div className="signin-card">
				<Link to="/">
					<img src="https://gottit-media.b-cdn.net/s/GOTTIT-LOGO.PNG" alt="Logo" className="signin-logo" />
				</Link>
				<h2 className="signin-title">Log in to your account</h2>
				<form onSubmit={formik.handleSubmit} noValidate autoComplete="off" className="signin-form">
					<input
						type="email"
						id="email_address"
						name="email_address"
						placeholder="Email"
						value={formik.values.email_address}
						onChange={formik.handleChange}
						required
						className="signin-input"
					/>
					{formik.errors.email_address && formik.touched.email_address && (
						<div className="si-error-message">{formik.errors.email_address}</div>
					)}

					<input
						type="password"
						id="password"
						name="password"
						placeholder="Password"
						value={formik.values.password}
						onChange={formik.handleChange}
						required
						className="signin-input"
					/>
					{formik.errors.password && formik.touched.password && (
						<div className="si-error-message">{formik.errors.password}</div>
					)}
					{/* {errorMessage && <div className="si-error-message">{errorMessage}</div>} */}
					{errorMessage && (
    					<div className="si-error-message">
        					{errorMessage}
   								 </div>
					)}
					<button type="submit" className="signin-button" disabled={loading}>
						{loading ? 'Logging in...' : 'Login'}
					</button>
					<div className="forgot-password">
						<Link to="/forgot-password" className="forgot-password-link">
							Forgot Password?
						</Link>
					</div>
					<ReCAPTCHA
						ref={recaptcha}
						sitekey={getEnv('GOOGLE_RECAPTCHA') || ''}
						size="invisible"
					/>
				</form>
				<div className="register-section">
					<p className="register-text">Register for faster transactions</p>
					<Link to="/create-account">
						<button className="register-button">REGISTER</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
