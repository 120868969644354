import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';
import '../../../styles/OrderDetails.scss';
import PageLoading from 'Pages/layout/PageLoading';

interface Media {
  media_url: string;
}

interface Item {
  product_name: string;
  product_variant_name?: string | null;
  product_price: string;
  quantity: number;
  media: Media[];
}

interface ShippingDetails {
    contact_name: string;
    contact_phone: string;
    contact_email_address: string;
    street_address_1: string;
    street_address_2?: string;
    town_city: string;
    state_province: string;
    zip_code: string;
    country: string;
    delivery_instructions?: string;
    landmark?: string;
  }

interface OrderDetails {
  id: string;
  order_reference: string;
  currency_iso_code: string;
  item_subtotal: string;
  admin_fee: string;
  shipping_fee: string;
  order_total: string;
  status: string;
  created_at: string;
  store_name: string;
  store_logo: string;
  items: Item[];
  shipping_details: string;
}

const OrderDetailsPage = () => {
    const { orderId, refId } = useParams<{ orderId: string; refId: string }>();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [shippingDetails, setShippingDetails] = useState<ShippingDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const currency = process.env.REACT_APP_CURRENCY;

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = getTokenOrDefault();
        const response = await tryFetch({
          url: `/g/orders/${orderId}`,
          method: 'GET',
          token,
        });

        if (response.is_success && response.data) {
            const data = response.data as OrderDetails;
            if (data.id === orderId && data.order_reference === refId) {
              setOrderDetails(data);
              const shippingDetailsJson = JSON.parse(data.shipping_details);
              setShippingDetails(shippingDetailsJson);
            } else {
              setError('Order details not found for the provided reference.');
            }
          } else {
            setError('Failed to fetch order details.');
          }
        } catch (error) {
          console.error('Error fetching order details:', error);
          setError('Something went wrong. Please try again later.');
        } finally {
          setLoading(false);
        }
      };
  
      fetchOrderDetails();
    }, [orderId, refId]);

  if (loading) {
    return <PageLoading/>;
  }

  if (error) {
    return <PageLoading/>;
  }

  if (!orderDetails) {
    return <p>Order details not found.</p>;
  }

  const {
    created_at,
    order_reference,
    status,
    items,
    store_name,
    store_logo,
    item_subtotal,
    admin_fee,
    shipping_fee,
    order_total,
  } = orderDetails;

  const handleTrackDelivery = () => {
    navigate(`/track/${orderId}`);
  };

  return (
    <div className="order-details-page">
      <div className="od-header">
        <button onClick={() => navigate('/orders')} className="od-back-button">
          &larr; Back
        </button>
        <h2>Order Details</h2>
      </div>
      <p className="od-created-at">{created_at}</p>
      <p className="od-order-ref">Reference No.: {order_reference}</p>
      <p className="od-status">
      Status: {status.replace(/_/g, ' ')}
      {status === 'TO_RECEIVE' && (
        <button className="od-track-delivery-btn" onClick={handleTrackDelivery}>Track Delivery</button>
      )}
    </p>
      <hr className="od-divider" />
      <h3 className='od-shipping-header'>Shipping Details</h3>
      {shippingDetails ? (
        <div className="od-shipping-details">
          <p><strong>Contact Name:</strong> {shippingDetails.contact_name}</p>
          <p><strong>Phone:</strong> {shippingDetails.contact_phone}</p>
          <p><strong>Email:</strong> {shippingDetails.contact_email_address}</p>
          <p><strong>Address:</strong> {`${shippingDetails.street_address_1}, ${shippingDetails.street_address_2 || ''} ${shippingDetails.town_city}, ${shippingDetails.state_province}, ${shippingDetails.zip_code}, ${shippingDetails.country}`}</p>
          {shippingDetails.delivery_instructions && (
            <p><strong>Delivery Instructions:</strong> {shippingDetails.delivery_instructions}</p>
          )}
          {shippingDetails.landmark && <p><strong>Landmark:</strong> {shippingDetails.landmark}</p>}
        </div>
      ) : (
        <p>Shipping details not available.</p>
      )}
      <hr className="od-divider" />
      <div className="od-merchant-details">
        <img src={store_logo} alt="Store Logo" className="od-store-logo" />
        <p className="od-store-name">{store_name}</p>
      </div>
      <div className="od-product-list">
        {items.map((item, index) => (
          <div key={index} className="od-product-item">
            <img
              src={item.media[0]?.media_url || '/default-image.png'}
              alt={item.product_name}
              className="od-product-image"
            />
            <div className="od-product-info">
              <p className="od-product-name">
                {item.product_name} x{item.quantity}
              </p>
              {item.product_variant_name && (
                <p className="od-product-variant">{item.product_variant_name}</p>
              )}
            </div>
            <p className="od-product-subtotal">
              {currency} {(Number(item.product_price) * item.quantity).toFixed(2)}
            </p>
          </div>
        ))}
      </div>
      <hr className="od-divider" />
      <div className="od-payment-card">
        <p>
          Items:
          <span className="od-right">{items.length} item(s)</span>
        </p>
        <p>
          Subtotal:
          <span className="od-right">
            {currency} {item_subtotal}
          </span>
        </p>
        <p>
          Service Fee (8%):
          <span className="od-right">
            {currency} {admin_fee}
          </span>
        </p>
        <p>
          Shipping Fee:
          <span className="od-right">
            {currency} {shipping_fee}
          </span>
        </p>
        <p className="od-total">
          Total Amount:
          <span className="od-total">
            {currency} {order_total}
          </span>
        </p>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
