import React, { useContext, useState, useEffect } from 'react';
import '../../../styles/Profile.scss';
import { MdOutlineHistory } from 'react-icons/md';
import { TbLogout2 } from 'react-icons/tb';
import { useSessionContext } from 'Context/SessionContext';
import { logout } from 'Security/actions';
import { getTokenOrDefault } from 'Helpers/Storage';
import { FaEdit } from 'react-icons/fa';
import T_addToCart from 'Components/modal/T_addToCart';
import { tryFetch } from 'Helpers/FetchAPI';
import { useProfile } from '../hooks/useProfile';

interface AddressInfo {
	longitude: string;
	latitude: string;
	street_address_1: string;
	street_address_2: string;
	state_municipality: string;
	city: string;
	zip_code: string;
	country: string;
	landmark?: string;
  }
  
  interface ContactInfo {
	contact_id: string;
	label: string;
	contact_name: string;
	contact_phone: string;
	contact_email_address: string;
	is_default_address: string;
	address_info: AddressInfo;
  }

const Profile = () => {
	const { session, setSession } = useSessionContext();
	const [contacts, setContacts] = useState<ContactInfo[]>([]);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);

	useEffect(() => {
		const fetchContacts = async () => {
		  const token = getTokenOrDefault();
		  try {
			const response = await tryFetch({
			  url: '/g/address',
			  method: 'GET',
			  token,
			});
	
			if (response.is_success && Array.isArray(response.data)) {
			  const formattedContacts: ContactInfo[] = response.data.map((item: any) => ({
				contact_id: item.contact_id,
				label: item.label,
				contact_name: item.contact_name,
				contact_phone: item.contact_phone,
				contact_email_address: item.contact_email_address,
				is_default_address: item.is_default_address,
				address_info: {
				  longitude: item.address_info.longitude,
				  latitude: item.address_info.latitude,
				  street_address_1: item.address_info.street_address_1,
				  street_address_2: item.address_info.street_address_2,
				  state_municipality: item.address_info.state_municipality,
				  city: item.address_info.city,
				  country: item.address_info.country,
				  zip_code: item.address_info.zip_code,
				},
			  }));
			  setContacts(formattedContacts);
			} else {
			  console.error('Error fetching contacts:', response.errors);
			}
		  } catch (error) {
			console.error('Error fetching contacts:', error);
		  }
		};
	
		fetchContacts();
	  }, []);

	const handleLogout = async () => {
		const token = getTokenOrDefault();
		const response = await logout(token);
		if(response.is_success) {
			setSession({
				isAuthenticated: false,
				user: null
			});
		}
	};


	  const handleEditClick = (contact: ContactInfo) => {
		setSelectedContactId(contact.contact_id);
		setIsEditModalOpen(true);
	  };
	
	  const closeEditModal = () => setIsEditModalOpen(false);
	
	  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);

	return (
		<React.Fragment>
			{session.user !== null ? (
				<div className="profile-container">
					<h2 className="profile-title">Profile</h2>
					<div className="profile-icons">
						<div className="icon">
							<MdOutlineHistory className="icon-image" size="30" />
						</div>
					</div>
					<hr className="profile-divider" />
					<p className="profile-detail"><strong>Full Name:</strong> {session.user.first_name} {session.user.last_name}</p>
					<p className="profile-detail"><strong>Email:</strong> {session.user.email_address}</p>
					<hr className="profile-divider" />
					
					{/* todo: Contact List Style */}
					{/* <div className="contact-list-section">
            <h3>Contact List</h3>
            {contacts.length > 0 ? (
              contacts.map((contact) => (
                <div key={contact.contact_id} className="contact-item">
                  <p>
                    <strong>{contact.contact_name}</strong>
                  </p>
                  <p>{contact.contact_phone}</p>
                  <p>{contact.address_info.street_address_1}</p>
                  <p>{contact.address_info.street_address_2}</p>
                  <p>
                    {contact.address_info.city}, {contact.address_info.state_municipality},{' '}
                    {contact.address_info.zip_code}
                  </p>
                  <button
                    className="edit-contact-button"
                    onClick={() => handleEditClick(contact)}
                  >
                    <FaEdit /> Edit
                  </button>
                </div>
              ))
            ) : (
              <p>No address yet</p>
            )}
          </div> */}


					<button onClick={handleLogout} className="logout-button">
						<TbLogout2 className="logout-icon" /> Logout
					</button>
				</div>
			) : <p>Loading...</p>}
		</React.Fragment>

	);
};

export default Profile;