import { Route, Routes } from 'react-router-dom';
import FavoritesPage from '../Pages/private/customer/Favorites';
import CartPage from '../Pages/private/customer/Cart';
import TrackPage from '../Pages/private/customer/Track';
import Profile from '../Pages/private/customer/Profile';
import Order from '../Pages/private/customer/Order';
import Review from '../Pages/private/customer/Review';
import Checkout from '../Pages/private/customer/Checkout';
import OrderDetailsPage from 'Pages/private/customer/OrderDetails';
import PrivateLayout, { ProtectedRoute } from 'Pages/layout/PrivateLayout';

const PrivateRoute = () => {
    return (
        <Routes>
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/orders" element={<ProtectedRoute><Order /></ProtectedRoute>} />
            <Route path="/orders/:orderId/:refId" element={<ProtectedRoute><OrderDetailsPage /></ProtectedRoute>} />
            <Route path="/cart" element={<ProtectedRoute><CartPage /></ProtectedRoute>} />
            <Route path="/check-out" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
            <Route path="/favorites" element={<ProtectedRoute><FavoritesPage /></ProtectedRoute>} />
            <Route path="/track/:orderId" element={<ProtectedRoute><TrackPage /></ProtectedRoute>} />
            <Route path="/review/:orderId/:productId" element={<ProtectedRoute><Review /></ProtectedRoute>} />
        </Routes>
    )
}

export default PrivateRoute