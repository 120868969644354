import React, { Ref } from 'react'
import { IMaskInput } from 'react-imask'

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const TPhoneMaskAdapter = React.forwardRef<HTMLElement, CustomProps>(
	function TPhoneMaskAdapter(props, ref) {
		const { onChange, ...other } = props;
		return (
			<IMaskInput
				{...other}
				mask="(#00)000-0000"
				definitions={{
					'#': /[1-9]/,
				}}
				inputRef={ref as Ref<HTMLInputElement>}
				onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
				overwrite
			/>
		);
	},
);

export default TPhoneMaskAdapter