import FavoritesProvider from "Context/favoritesContext";
import SessionContextProvider from "Context/SessionContext";
import { useUserSession } from "Security/hooks/useUserSession";
import { FollowingMerchantProvider } from "Context/FollowingMerchantContext";
import PublicRoute from "./PublicRoute";
import SecurityRoute from "./SecurityRoute";
import PrivateRoute from "./PrivateRoute";
import { Route, Routes } from "react-router-dom";
import NotFoundRoute from "./NotFoundRoute";
import PageContextProvider from "Context/PageContext";
import PageLoading from "Pages/layout/PageLoading";
import React from "react";

const AppContent = () => {
	const { session, setSession, isPageLoading, setIsPageLoading } = useUserSession();

	return (
		<SessionContextProvider value={{ session, setSession }}>
			<PageContextProvider value={{ isPageLoading, setIsPageLoading }}>
				<FavoritesProvider>
						<FollowingMerchantProvider>
							{isPageLoading ? <PageLoading /> : (
								<React.Fragment>
									<SecurityRoute />
									<PrivateRoute />
									<PublicRoute />
									<NotFoundRoute />
								</React.Fragment>
							)}
						</FollowingMerchantProvider>
				</FavoritesProvider>
			</PageContextProvider>
		</SessionContextProvider>
	);
};

export default AppContent;