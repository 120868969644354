import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from '../../hooks/useLocation';
import { SignInSchema } from '../schema/security';
import { useSessionContext } from 'Context/SessionContext';
import {fetchProfile, tryAuthenticate } from 'Security/actions';
import { getTokenOrDefault } from 'Helpers/Storage';

export const useAuthenticate = () => {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const { coordinates } = useLocation();
	const { setSession } = useSessionContext();

	const recaptcha = useRef<ReCAPTCHA>(null);

	const formik = useFormik({
		initialValues: {
			email_address: '',
			password: '',
			g_token: '',
			location: '',
		},
		validationSchema: SignInSchema,
		onSubmit: async (values) => {
			setLoading(true);
			setErrorMessage('');

			values.location = coordinates ?? '';

			const token = await recaptcha.current?.executeAsync();
			if (!token) {
				setErrorMessage('Recaptcha verification failed. Please try again.');
				setLoading(false);
				return;
			}
			values.g_token = token;

			try {
				const authenticate = await tryAuthenticate(values);
				if (authenticate.is_success) {
					const _token = getTokenOrDefault();
					const profile = await fetchProfile(_token);
					if (profile.is_success && profile.status_code === 200) {
						const data: any = profile.data;
						setSession({
							isAuthenticated: true,
							user: data
						});
					}
					return;
				}

			if (authenticate.errors && authenticate.errors.length > 0) {
				setErrorMessage(authenticate.errors[0]);
			} else {
				setErrorMessage('Invalid Email or Password');
			}
		} catch (error) {
			console.error('Error during login:', error);
			setErrorMessage('Invalid Email or Password');
		} finally {
			recaptcha.current?.reset();
			setLoading(false);
		}
	},
	});

	return { formik, recaptcha, loading, errorMessage };
};
