import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { tryFetch } from '../../../Helpers/FetchAPI';
import '../../../styles/Others.scss';

interface Merchant {
  id: string;
  merchant_name: string;
  category: string;
  hero_url: string;
  logo_url?: string;
  status: string;
  featured?: { title: string; mime_type: string; url: string; source: string }[] | null;
}

interface DefaultImage {
  id: string;
  type: string;
  image_url: string;
}

const Others: React.FC = () => {
  const [othersMerchants, setOthersMerchants] = useState<Merchant[]>([]);
  const [defaultProfileImg, setDefaultProfileImg] = useState<string>('');

  useEffect(() => {
    const fetchDefaultProfileImg = async () => {
      try {
        const response = await fetch('/assets/default_img.json');
        if (!response.ok) {
          throw new Error('Failed to fetch default profile image');
        }
        const data: DefaultImage[] = await response.json();
        const defaultImage = data.find((img) => img.type === 'default profile');
        setDefaultProfileImg(defaultImage?.image_url || '');
      } catch (error) {
        console.error('Error fetching default profile image:', error);
      }
    };

    const fetchOthersMerchants = async () => {
      try {
        const response = await tryFetch({
          url: '/merchants',
          method: 'GET',
        });

        if (!response.is_success) {
          throw new Error(response.error || 'Failed to fetch merchants');
        }

        const merchants: Merchant[] = (response.data as any) || [];

        const sortedMerchants = merchants
        .filter((merchant) => merchant.category === 'Others')
        .sort((a, b) => a.merchant_name.localeCompare(b.merchant_name));
  
      setOthersMerchants(sortedMerchants);
    } catch (error) {
      console.error('Error fetching Others merchants:', error);
    }
  };

    fetchDefaultProfileImg();
    fetchOthersMerchants();
  }, []);

  return (
    <div className="others-page">
      <h2>Other Merchants</h2>
      <div className="merchant-list grid-view">
        {othersMerchants.map((merchant) => (
          <Link to={`/merchant/${merchant.id}`} key={merchant.id} className="merchant-item">
            <div className="merchant-image-wrapper">
              <img
                src={merchant.hero_url || defaultProfileImg}
                alt={merchant.merchant_name}
                className="merchant-image"
              />
            </div>
            <p className="merchant-name">{merchant.merchant_name}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Others;
