import React, { useState, useEffect } from "react";
import '../../styles/T_addContact.scss';
import DragableMap from "../map/dragableMap";
import { tryFetch } from "Helpers/FetchAPI";
import { getTokenOrDefault } from 'Helpers/Storage';
import TPhoneMaskAdapter from "Components/TPhoneMaskAdapter";
import { Input, Button, Typography } from "@mui/joy";

interface AddressInfo {
    latitude: string;
    longitude: string;
    street_address_1: string;
    street_address_2: string;
    state_province: string;
    town_city: string;
    zip_code: string;
    country: string;
    landmark?: string;
    region: string;
  }
  
  interface ContactInfo {
    contact_id: string;
    label: string;
    contact_name: string;
    contact_phone: string;
    contact_email_address: string;
    is_default_address: boolean;
    delivery_instructions: string;
    address_info: AddressInfo
  }

interface AddContactModalProps {
  contact?: ContactInfo;
  onClose: () => void;
  onSave: (updatedContact: ContactInfo) => void;
}

const AddContactModal: React.FC<AddContactModalProps> = ({ contact, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        label: "",
        contactName: "",
        contactNumber: "",
        contactEmail: "",
        defaultAddress: false,
        delInstructions: "",
        latitude: "",
        longitude: "",
        streetAddress1: "",
        streetAddress2: "",
        city: "",
        province: "",
        zipCode: "",
        country: "",
        landmark: "",
        region: "",
        
    });

    useEffect(() => {
        if (contact) {
          const addressInfo = contact.address_info || {};
          setFormData({
            label: contact.label,
            contactName: contact.contact_name,
            contactNumber: contact.contact_phone,
            contactEmail: contact.contact_email_address,
            defaultAddress: contact.is_default_address,
            delInstructions: contact.delivery_instructions,
            latitude: addressInfo.latitude,
            longitude: addressInfo.longitude,
            streetAddress1: addressInfo.street_address_1,
            streetAddress2: addressInfo.street_address_2,
            city: addressInfo.town_city,
            province: addressInfo.state_province,
            zipCode: addressInfo.zip_code,
            country: addressInfo.country,
            landmark: addressInfo.landmark || "",
            region: addressInfo.region,
          });
        }
      }, [contact]);

      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name] : value,
        }));
    };
    
      const handleLocationChange = (details: {
        lat: string;
        lng: string;
        city: string;
        province: string;
        region: string;
        streetAddress1: string;
        streetAddress2: string;
      }) => {
        setFormData((prevData) => ({
            ...prevData,
            streetAddress1: details.streetAddress1,
            streetAddress2: details.streetAddress2,
            city: details.city,
            province: details.province,
            region: details.region,
            latitude: details.lat,
            longitude: details.lng
          }));
        };

        const handleSave = async () => {
          const contactData = {
            contact_id: contact?.contact_id || null,
            label: formData.label,
            contact_name: formData.contactName,
            contact_phone: formData.contactNumber,
            contact_email_address: formData.contactEmail,
            is_default_address: formData.defaultAddress,
            delivery_instructions: formData.delInstructions,
            address_info: {
                latitude: formData.latitude,
                longitude: formData.longitude,
                street_address_1: formData.streetAddress1,
                street_address_2: formData.streetAddress2,
                state_province: formData.province,
                town_city: formData.city,
                zip_code: formData.zipCode,
                country: formData.country,
                landmark: formData.landmark,
                region: formData.region,
            },
        };
          const token = getTokenOrDefault();

          try {
            const response = await tryFetch({
              url: "/g/address",
              method: "POST",
              payload: contactData,
              token,
            });
      
            if (response.is_success) {
              const updatedContact = response.data as ContactInfo;
              onSave(updatedContact); 
              onClose();
            } else {
              console.error("Failed to save contact:", response.errors);
              alert("Failed to save contact. Please try again.");
            }
          } catch (error) {
            console.error("Error while saving contact:", error);
            alert("An error occurred. Please try again.");
          }
        };

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-header">Contact Information</h2>
        <div className="modal-body">
        <Input
          type="text"
          placeholder="Label"
          name="label"
          value={formData.label}
          onChange={handleInputChange}
          variant="plain" 
          color="primary"
        />
        <Input
          type="text"
          placeholder="Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleInputChange}
          variant="plain" 
        />
        <Input
          type="text"
          placeholder="Contact Number"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleInputChange}
          required
          slotProps={{ input: {component: TPhoneMaskAdapter}}}
          startDecorator={<Typography>+63</Typography>}
          variant="plain" 

        />
        <Input
           type="email"
           placeholder="Email"
           name="contactEmail"
           value={formData.contactEmail}
           onChange={handleInputChange}
           variant="plain" 
        />
        <Input
           placeholder="Delivery Instructions"
           name="delInstructions"
           value={formData.delInstructions}
           onChange={handleInputChange}
           variant="plain" 
        />
        <label className="default-checkbox">
            <input
              type="checkbox"
              name="defaultAddress"
              checked={formData.defaultAddress}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  defaultAddress: e.target.checked,
                }))
              }
            />
            Use as default address
          </label>
        <div className="search-bar">
          <DragableMap onLocationChange={handleLocationChange} />
        </div>
        <Input
          type="text"
          placeholder="Street Address 1"
          name="streetAddress1"
          value={formData.streetAddress1}
          onChange={handleInputChange}
          variant="plain" 
        />
        <Input
          type="text"
          placeholder="Street Address 2"
          name="streetAddress2"
          value={formData.streetAddress2}
          onChange={handleInputChange}
          variant="plain" 
        />
        <Input type="text" placeholder="City" name="city" value={formData.city} onChange={handleInputChange}
        variant="plain"  />
        <Input
          type="text"
          placeholder="Province"
          name="province"
          value={formData.province}
          onChange={handleInputChange}
          variant="plain" 
        />
        <Input
          type="text"
          placeholder="Zip Code"
          name="zipCode"
          value={formData.zipCode}
          onChange={handleInputChange}
          variant="plain" 
        />
        <Input
          type="text"
          placeholder="Region"
          name="region"
          value={formData.region}
          onChange={handleInputChange}
          variant="plain" 
        />
        <Input
          type="text"
          placeholder="Country"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          variant="plain" 
        />
        <Input
          type="text"
          placeholder="Enter other Details (e.g. Landmark)"
          name="landmark"
          value={formData.landmark}
          onChange={handleInputChange}
          variant="plain" 
        />
         </div>
         <div className="modal-footer">
        <Button className="modal-save-button" onClick={handleSave}>
          Save
        </Button>
        <Button className="modal-close-button" onClick={onClose}>
          Cancel
        </Button>
        </div>
      </div>
    </div>
  );
};

export default AddContactModal;
