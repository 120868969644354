import Footer from 'Components/Footer/Footer'
import Navbar from 'Components/Navbar/Navbar'
import { useSessionContext } from 'Context/SessionContext'
import React from 'react'
import { Outlet } from 'react-router-dom'

const PublicLayout = () => {
    return <React.Fragment>
        <Navbar/>
        <div className="content">
        <Outlet/>
        </div>
        <Footer/>
    </React.Fragment>
}

export default PublicLayout