import React, { useEffect, useState } from 'react';
import '../../styles/Terms&Conditions.scss';
import PageLoading from 'Pages/layout/PageLoading';

interface ContentItem {
  title: string;
  description: string;
  content: string[];
}

interface TermsConditionsData {
  id: number;
  last_modified: string;
  subject: string;
  header: string;
  description: string;
  contents: ContentItem[];
}

const TermsConditions: React.FC = () => {
  const [termsData, setTermsData] = useState<TermsConditionsData | null>(null);

  useEffect(() => {
    fetch('/assets/terms.json')
      .then((response) => response.json())
      .then((data) => setTermsData(data))
      .catch((error) => console.error('Error loading terms:', error));
  }, []);

  if (!termsData) {
    return <PageLoading />;
  }

  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>{termsData.subject}</h1>
        <p className="last-modified">Last Modified: {termsData.last_modified}</p>
      </header>
      <section className="terms-intro">
        <h2>{termsData.header}</h2>
        <p>{termsData.description}</p>
      </section>
      {termsData.contents.map((item, index) => (
        <section key={index} className="terms-section">
          <h3>{item.title}</h3>
          <p>{item.description}</p>
          {item.content.map((point, idx) => (
            <div
              key={idx}
              className="formatted-content"
              dangerouslySetInnerHTML={{ __html: point }}
            />
          ))}
        </section>
      ))}
    </div>
  );
};

export default TermsConditions;
