import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import '../../../styles/Merchant.scss';
import { FavoritesContext } from '../../../Context/favoritesContext';
import { FaRegSquarePlus, FaCheck } from "react-icons/fa6";
import { FollowingMerchantContext } from '../../../Context/FollowingMerchantContext';
import { MdOutlineReport } from "react-icons/md";
import { tryFetch } from '../../../Helpers/FetchAPI';
import { RiErrorWarningFill } from "react-icons/ri";
import { IoCalendarNumber, IoTimeSharp } from "react-icons/io5";
import { getTokenOrDefault } from 'Helpers/Storage';

interface Merchant {
  id: string;
  merchant_name: string;
  description: string;
  category: string;
  hero_url: string;
  logo_url?: string;
  status?: string;
  featured?: { title: string; mime_type: string; url: string; source: string }[] | null; 
  days_open?: string | null; 
  hours_open?: string | null; 
  hours_close?: string | null; 
}

interface Product {
  id: string;
  store_id: string;
  product_id: string;
  product_name: string;
  product_type: string;
  product_description: string;
  specifications: {
    attribute_name: string;
    attribute_value: string;
  }[];
  unit_price: string;
  stock_count: number;
  status: string;
  media: {
    id: string;
    media_url: string;
    media_source: string;
  }[];
  variants: {
    attributes: {
      attribute_name: string;
      attribute_options: string;
    }[];
    items: {
      id: string;
      attribute_options: string;
      attribute_checksum: string;
      stock_count: number;
      unit_price: string;
      media_url: string;
    }[];
  };
}

const formatDaysOpen = (days: string) => {
  const dayMap = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
  const dayIndexes = days.split(',').map(day => dayMap.indexOf(day)).sort((a, b) => a - b);

  const ranges: string[] = [];
  let start = dayIndexes[0];
  for (let i = 1; i <= dayIndexes.length; i++) {
    if (i === dayIndexes.length || dayIndexes[i] !== dayIndexes[i - 1] + 1) {
      const end = dayIndexes[i - 1];
      ranges.push(start === end ? dayMap[start] : `${dayMap[start]}-${dayMap[end]}`);
      start = dayIndexes[i];
    }
  }

  return ranges.join(', ');
};

const formatTimeTo12Hour = (time: string) => {
  const [hour, minute] = time.split(':').map(Number);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
};


const MerchantPage = () => {
  const { id } = useParams<{ id: string }>();
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const { likedProducts, toggleLike,} = useContext(FavoritesContext) || {};
  const [isGridView, setIsGridView] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<{ url: string; type: string, source: string} | null>(null);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});
  const location = useLocation();
  const [showSeeAll, setShowSeeAll] = useState(false);
  const productContainerRef = useRef<HTMLDivElement>(null);
  const { toggleFollow, followedMerchants } = useContext(FollowingMerchantContext) || {};
  const isFollowing = merchant && followedMerchants?.some((m) => m.id === merchant.id);
  const [wishlistProducts, setWishlistProducts] = useState<string[]>([]);

  const currency = process.env.REACT_APP_CURRENCY;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const merchantResponse = await tryFetch({
          url: `/merchants/${id}`,
          method: 'GET',
        });

        if (merchantResponse.is_success) {
          setMerchant(merchantResponse.data as Merchant);

          const productResponse = await tryFetch({
            url: `/merchants/${id}/products`,
            method: 'GET',
          });

           if (productResponse.is_success && Array.isArray(productResponse.data)) {
            const transformedProducts = productResponse.data.map((product: any) => ({
              ...product,
              variants: {
                attributes: product.variants?.attributes || [],
                items: product.variants?.items || [],
              },
              media: product.media || [],
            }));
            setProducts(transformedProducts as Product[]);
          } else {
            console.error('Failed to fetch products:', productResponse.error);
          }
        } else {
          console.error('Failed to fetch merchant:', merchantResponse.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchWishlistData = async () => {
      try {
        const token = getTokenOrDefault()
        const wishlistResponse = await tryFetch({
          url: '/g/wishlist',
          method: 'GET',
          token,
        });

        if (Array.isArray(wishlistResponse.data) && wishlistResponse.data.every(item => 'item_id' in item)) {
  setWishlistProducts(wishlistResponse.data.map(item => item.item_id));
} else {
  console.error('Unexpected data format in wishlist response');
}
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    fetchMerchantData();
    fetchWishlistData();
  }, [id]);


  const toggleView = () => {
    setIsGridView((prevState) => !prevState);
    setShowSeeAll(!isGridView);
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (productContainerRef.current) {
        const { scrollWidth, clientWidth } = productContainerRef.current;
        setShowSeeAll(scrollWidth > clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [products]);

  if (!merchant) {
    return <div>Merchant not found</div>;
  }

  const hasFeaturedContent =
  Array.isArray(merchant?.featured) && merchant.featured.length > 0;
  

  const handleImageLoad = (imageKey: string) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [imageKey]: true,
    }));
  };

  const handleToggleLike = async (productId: string) => {
    const token = getTokenOrDefault();
  
    if (wishlistProducts.includes(productId)) {
      try {
        const response = await tryFetch({
          url: `/g/wishlist`,
          method: 'DELETE',
          token,
          payload: { item_id: productId },
        });
  
        if (response.is_success) {
          setWishlistProducts((prevState) =>
            prevState.filter((id) => id !== productId)
          );
        } else {
          console.error('Failed to remove from wishlist:', response.error);
        }
      } catch (error) {
        console.error('Error removing from wishlist:', error);
      }
    } else {

      try {
        const response = await tryFetch({
          url: `/g/wishlist`,
          method: 'POST',
          token,
          payload: { item_id: productId },
        });
  
        if (response.is_success) {
          setWishlistProducts((prevState) => [...prevState, productId]);
        } else {
          console.error('Failed to add to wishlist:', response.error);
        }
      } catch (error) {
        console.error('Error adding to wishlist:', error);
      }
    }
  };

  const openModal = (media: { url: string; type: string; source: string }) => {
    setSelectedMedia(media);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedMedia(null);
  };

  const handleFollowClick = () => {
    if (merchant && toggleFollow) {
      toggleFollow(merchant);
    }
  };

  return (
    <div className="merchant-page">
      <div
        className="banner"
        style={{
          backgroundImage: `url(${merchant.hero_url})`,
        }}
      >
        <div className="banner-overlay">
          <h1>{merchant.merchant_name}</h1>
          <p>{merchant.description}</p>
          <button
            onClick={handleFollowClick}
            className={`follow-button ${isFollowing ? 'following' : ''}`}
          >
            {isFollowing ? <><FaCheck size="18" className="follow-icon" /> Following</> : <><FaRegSquarePlus size="18" className="follow-icon" /> Follow</>}
          </button>

          {merchant.days_open || (merchant.hours_open && merchant.hours_close) ? (
        <div className="store-info">
          {merchant.days_open && (
            <span className="store-days">
              <IoCalendarNumber size={18} />: {formatDaysOpen(merchant.days_open)}
            </span> 
          )}
          
          {merchant.hours_open && merchant.hours_close && (
            <span className="store-hours">
              <IoTimeSharp size={18} />: {formatTimeTo12Hour(merchant.hours_open)} - {formatTimeTo12Hour(merchant.hours_close)}
            </span>
          )}
        </div>
      ) : null}

        </div>
      </div>

      {merchant.status === "ON_VACATION" && (
      <div className="vacation-warning">
        <RiErrorWarningFill className="v-warning-icon" />
        <span>Seller is on vacation. Shipment will be delayed.</span>
      </div>
    )}
    
      <div className="products-section">
        <div className="products-header">
          <h2>Products</h2>
          {showSeeAll && (
            <button className="see-all" onClick={toggleView}>
              {isGridView ? 'Back to Slider' : 'See All'}
            </button>
          )}
        </div>
        <div className={isGridView ? 'products-grid' : 'products-slider'} ref={productContainerRef}>
          {(products?.length ?? 0) > 0 ? (
            products.map(product => (
              <div key={product.product_id} className="product-card">
                <div className="product-image-wrapper">
              <div className="product-image">
                <Link to={`/merchant/${product.store_id}/product/${product.id}`}>
                  <img
                    src={product.media[0]?.media_url}
                    alt={product.product_name}
                    width="169"
                    height="169"
                    className={loadedImages[`product-${product.product_id}`] ? 'loaded' : ''}
                    onLoad={() => handleImageLoad(`product-${product.product_id}`)}
                  />
                </Link>
                <button
                  className="heart-icon"
                  onClick={() => handleToggleLike(product.id)}
                >
                  {/* {likedProducts?.[product.id] ? */}
                  {wishlistProducts.includes(product.id) ? (
                  <FaHeart color="#832b99" size="22" />
                ) : (
                  <FaRegHeart size="22" />
                )}
                </button>
              </div>
              </div>
              <p className="product-name">
                <Link
                  to={`/merchant/${product.store_id}/product/${product.id}`}
                  style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
                  title={product.product_name}
                >
                  {product.product_name.length > 25
                      ? `${product.product_name.slice(0, 25)}...`
                    : product.product_name}
                </Link>
              </p>
              <p className="product-price">
              {currency} {product.unit_price ? `${parseFloat(product.unit_price).toFixed(2)}` : ""}
              </p>
            </div>
            ))
          ) : (
            <p>No products available</p> 
          )}
        </div>
      </div>

      {hasFeaturedContent && (
        <div className="featured-content">
          <h2>Featured Content</h2>
          <div className="content-ft">
              {merchant.featured?.map((content, index) => (
              content.url && (
                <div
                  key={index}
                  className="featured-item"
                  onClick={() => openModal({ url: content.url, type: content.mime_type, source: content.source })}
                >
                  {content.source === "CDN" ? (
                    <img
                      src={content.url}
                      alt={`Featured ${index}`}
                      className={`featured-media ${loadedImages[`featured-${index}`] ? 'loaded' : ''}`}
                      onLoad={() => handleImageLoad(`featured-${index}`)}
                    />
                  ) : (
                    <iframe
                      src={content.url}
                      title={`Video content ${index + 1}`}
                      allowFullScreen
                      className="featured-vmedia"
                      width={"360px"}
                      height={"500px"}
                    />
                  )}
                </div>
              ) //
              ))}
          </div>
        </div>

      )}
      {modalOpen && selectedMedia && (
        <div className="modal">
          <div className="modal-content">
            {selectedMedia.source === "CDN" ? (
              <img src={selectedMedia.url} alt="Modal Content" className="modal-media"  />
            ) : (
              <iframe src={selectedMedia.url.includes("youtube.com") ? selectedMedia.url.replace("watch?v=", "embed/") : selectedMedia.url}
              title="Modal Video Content" allow="fullscreen" allowFullScreen className="modal-media"  />
            )}
            <button className="close-modal" onClick={closeModal}><IoClose size ="28"/></button>
          </div>
        </div>
      )}

      {/* <div className="reviews-section">
      <h2>Reviews</h2></div>  suggestion: Gawin tong average rating ng merchant base sa reviews.*/}
      <button className="report-button">
      <MdOutlineReport size={24} />
      </button>

    </div>
  );
};

export default MerchantPage;
