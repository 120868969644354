import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import '../../styles/T_addToCart.scss';
import { TbMoodEmpty } from 'react-icons/tb';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';
import { useSnackbar } from 'notistack';

type VariantItem = {
  id: string;
  attribute_options: string;
  attribute_checksum: string;
  stock_count: number;
  unit_price: string;
  media_url: string;
};

type Attribute = {
  attribute_name: string;
  attribute_options: string;
};

type Media = {
  id: string;
  media_source: string;
  media_url: string;
};

type Merchant = {
  id: string;
  store_name: string;
  store_logo?: string;
};

type Product = {
  id: string;
  unit_price: string;
  stock_count: number;
};

type T_addToCartProps = {
  productData: Product;
  productId: string;
  productName: string;
  productMedia: Media[];
  productVariants: {
    attributes: Attribute[];
    items: VariantItem[];
  };
  merchant: Merchant;
  onClose: () => void;
  sourceKey: number;
  onDeleteFromWishlist?: (itemId: string) => void;
};

const T_addToCart: React.FC<T_addToCartProps> = ({
  productData,
  productId,
  productName,
  productMedia,
  productVariants,
  merchant,
  onClose,
  sourceKey = 0,
  onDeleteFromWishlist,
}) => {

  const { enqueueSnackbar } = useSnackbar();

  const [selectedAttributes, setSelectedAttributes] = useState<Record<string, string>>({});
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOutOfStockModal, setShowOutOfStockModal] = useState(false);

  const currency = process.env.REACT_APP_CURRENCY;
  const hasVariants = productVariants.items.length > 0;
  const sortedAttributes = Object.keys(selectedAttributes)
    .sort()
    .map((key) => selectedAttributes[key])
    .join(',');

  const selectedVariant = hasVariants
  ? productVariants.items.find((item) => item.attribute_options === sortedAttributes)
  : null;

  const unitPrice = hasVariants
    ? selectedVariant?.unit_price || '0.00'
    : productData.unit_price || '0.00';

  const stockCount = hasVariants
    ? selectedVariant?.stock_count || 0
    : productData.stock_count || 0;

  const handleAttributeSelect = (attributeName: string, value: string) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [attributeName]: value,
    }));
    setErrorMessage('');
    setQuantity(1);
  };

  // const handleQuantityChange = (delta: number) => {
  //   const stockCount = selectedVariant?.stock_count ?? 0;
  //   setQuantity((prev) => Math.max(1, Math.min(prev + delta, stockCount)));
  // };

  const handleQuantityChange = (delta: number) => {
    setQuantity((prev) => Math.max(1, Math.min(prev + delta, stockCount)));
  };

  const handleAddToCart = async () => {
    if (hasVariants && !selectedVariant) {
      setErrorMessage('Please select all variant options.');
      return;
    }

    if (hasVariants && selectedVariant?.stock_count === 0) {
      setShowOutOfStockModal(true);
      return;
    }

    const token = getTokenOrDefault();
    const payload = {
      store_id: merchant?.id || '',
      item_id: selectedVariant?.id || productId, 
      source_key: sourceKey,
      quantity,
    };

    try {
      const response = await tryFetch({
        url: '/g/cart',
        method: 'POST',
        token,
        payload,
      });
      if (response.is_success) {
        enqueueSnackbar('Product added to cart!', { variant: 'success' });
         if (sourceKey === 1 && onDeleteFromWishlist) {
          onDeleteFromWishlist(productId); 
        }
        onClose();
      } else {
        enqueueSnackbar('Failed to add product to cart. Please try again.', { variant: 'error' });
        setErrorMessage('Failed to add item to cart. Please try again.');
      }
    } catch (error) {
      enqueueSnackbar('Please try again later.', { variant: 'error' });
      setErrorMessage('Please try again later.');
    }
  };

  const isOptionDisabled = (attributeName: string, value: string) => {
    const updatedAttributes = { ...selectedAttributes, [attributeName]: value };
    const filteredOptions = Object.keys(updatedAttributes)
      .sort()
      .map((key) => updatedAttributes[key])
      .join(',');
    return !productVariants.items.some((item) =>
      item.attribute_options.startsWith(filteredOptions)
    );
  };

  return (
    <div className="variant-card-overlay">
      <div className="variant-card">
        <IoClose className="close-icon" onClick={onClose} size="30" />
        <img
          src={selectedVariant?.media_url || productMedia[0]?.media_url}
          alt={productName}
          className="variant-card-image"
        />
        <h3>{productName}</h3>
        <p className="variant-price">{currency} {parseFloat(unitPrice).toFixed(2)}</p>
        <p className="variant-stock">Stock: {stockCount}</p>
        {hasVariants && productVariants.attributes.map((attribute) => (
          <div key={attribute.attribute_name}>
            <h4>{attribute.attribute_name}</h4>
            <div className="variant-list">
              {attribute.attribute_options.split(',').map((option) => (
                <div
                  key={option}
                  className={`variant-option ${
                    selectedAttributes[attribute.attribute_name] === option ? 'selected' : ''
                  } ${isOptionDisabled(attribute.attribute_name, option) ? 'disabled' : ''}`}
                  onClick={() =>
                    !isOptionDisabled(attribute.attribute_name, option) &&
                    handleAttributeSelect(attribute.attribute_name, option)
                  }
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        ))}

        <h4>Qty.</h4>
        <div className="quantity-control">
          <button onClick={() => handleQuantityChange(-1)}>-</button>
          <span>{quantity}</span>
          <button onClick={() => handleQuantityChange(1)}>+</button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button className="confirm-add-to-cart" onClick={handleAddToCart}>
          Add to Cart
        </button>
      </div>
      {showOutOfStockModal && (
        <div className="out-of-stock-modal-overlay">
          <div className="out-of-stock-modal">
            <TbMoodEmpty className="out-of-stock-icon" size={48} />
            <p className="os-text">Oops. This Product is out of Stock!</p>
            <button className="os-close-btn" onClick={() => setShowOutOfStockModal(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default T_addToCart;
