import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../../styles/Checkout.scss';
import AddContactModal from '../../../Components/modal/T_addContact';
import ContactListModal from '../../../Components/modal/T_contactList';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';
import PageLoading from 'Pages/layout/PageLoading';

interface AddressInfo {
  latitude: string;
  longitude: string;
  street_address_1: string;
  street_address_2: string;
  state_province: string;
  town_city: string;
  zip_code: string;
  country: string;
  landmark?: string;
  region: string;
}

interface ContactInfo {
  contact_id: string;
  label: string;
  contact_name: string;
  contact_phone: string;
  contact_email_address: string;
  is_default_address: boolean;
  delivery_instructions?: string
  address_info: AddressInfo;
}

interface CartItem {
  cart_item_id: string;
  product_name: string;
  variation_name: string | null;
  unit_price: string;
  quantity: number;
  total_amount: string;
  store_id: string;
}

interface ShippingResponse {
  request_id: string;
  cart: {
    store_id: string;
    shipping_fee: string;
    admin_fee: string;
  }[];
}

interface OrderProcessingResponse {
  payment_url: string;
}

interface ContactListModalProps {
  contacts: ContactInfo[];
  onSave: (selectedContact: ContactInfo) => void;
  onClose: () => void;
}

const Checkout: React.FC = () => {
  const location = useLocation();
  const { selectedItems = [], totalAmount = '0.00' } = location.state || {};
  const [contacts, setContacts] = useState<ContactInfo[]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactInfo | null>(null);
  const [isContactListModalOpen, setIsContactListModalOpen] = useState(false);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [shippingFee, setShippingFee] = useState<number>(0);
  const [loadingShipping, setLoadingShipping] = useState<boolean>(false);
  const [serviceFee, setServiceFee] = useState<number>(0);
  const [isPolicyChecked, setIsPolicyChecked] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const subtotal = parseFloat(totalAmount);
  const grandTotal = subtotal + shippingFee + serviceFee;

  const currency = process.env.REACT_APP_CURRENCY;

  useEffect(() => {
    const token = getTokenOrDefault();
    const fetchContacts = async () => {
      try {
        const response = await tryFetch({
          url: '/g/address',
          method: 'GET',
          token,
        });
    
        if (response.is_success && Array.isArray(response.data)) {
          const contactsData = response.data.map((contact) => ({
            ...contact,
            is_default_address: Boolean(contact.is_default_address),
          })) as ContactInfo[];

          setContacts(contactsData);

          const defaultContact = contactsData.find((contact) => contact.is_default_address);
          setSelectedContact(defaultContact || contactsData[0] || null);
        } else {
          console.error('Error fetching contacts:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, []);

  const truncateText = (text: string, limit: number) =>
    text.length > limit ? `${text.slice(0, limit)}...` : text;

  const calculateShippingFee = async () => {
    if (!selectedContact) return;

    setLoadingShipping(true);
    try {
      const token = getTokenOrDefault();
      const stores = Array.from(new Set(selectedItems.map((item: CartItem) => item.store_id)));

      const cart = stores.map((storeId) => ({
        store_id: storeId,
        items: selectedItems
          .filter((item: CartItem) => item.store_id === storeId)
          .map((item: CartItem) => item.cart_item_id),
      }));

      const payload = {
        customer_address_id: selectedContact.contact_id,
        cart,
      };

      const response = await tryFetch({
        url: '/g/calculate-shipping',
        method: 'POST',
        token,
        payload,
      });

      if (response.is_success && response.data) {
        const shippingData = (response.data as ShippingResponse).cart;
        const requestData = (response.data as ShippingResponse);
  
        let totalShippingFee = 0;
        let totalAdminFee = 0;
  
        shippingData.forEach((store: any) => {
          totalShippingFee += parseFloat(store.shipping_fee || 0);
          totalAdminFee += parseFloat(store.admin_fee || 0);
        });
  
        setShippingFee(totalShippingFee);
        setServiceFee(totalAdminFee);
        setRequestId(requestData.request_id);

      } else {
        console.error('Failed to calculate shipping:', response.errors);
      }
    } catch (error) {
      console.error('Error calculating shipping fee:', error);
    } finally {
      setLoadingShipping(false);
    }
  };

  const handlePlaceOrder = async () => {
    if (!isPolicyChecked) return;

    if (!requestId) {
      console.error('Request ID not available.');
      return;
    }

    setLoading(true);

    try {
      const token = getTokenOrDefault();
      const payload = { request_id: requestId };

      const response = await tryFetch({
        url: '/g/process-order',
        method: 'POST',
        token,
        payload,
      });

      if (response.is_success && response.data) {
        const orderData = response.data as OrderProcessingResponse;
        window.location.href = orderData.payment_url;
      } else {
        console.error('Failed to process order:', response.errors);
      }
    } catch (error) {
      console.error('Error processing order:', error);
    }
  };

  useEffect(() => {
    if (selectedContact) {
      calculateShippingFee();
    }
  }, [selectedContact, selectedItems]);

  const handleAddContact = () => {
    setIsAddContactModalOpen(true);
  };

  const handleContactListModal = () => {
    setIsContactListModalOpen(true);
  };

  const handleContactSave = async (newContact: ContactInfo) => {
    try {
      const token = getTokenOrDefault();
      const response = await tryFetch({
        url: "/g/address",
        method: "GET",
        token,
      });
  
      if (response.is_success && Array.isArray(response.data)) {
        const updatedContacts = response.data.map((contact) => ({
          ...contact,
          is_default_address: Boolean(contact.is_default_address),
        })) as ContactInfo[];
  
        setContacts(updatedContacts);
  
        const addedContact = updatedContacts.find(
          (contact) => contact.contact_id === newContact.contact_id
        );
        setSelectedContact(addedContact || updatedContacts[0] || null);
      } else {
        console.error("Error fetching contacts after adding:", response.errors);
      }
    } catch (error) {
      console.error("Error fetching contacts after adding:", error);
    } finally {
      setIsAddContactModalOpen(false);
    }
  };


  const handlePolicyCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPolicyChecked(e.target.checked);
  };

  return (
    <div className="checkout-page">
      <h1 className="checkout-header">CHECKOUT</h1>

      <div className="checkout-container">
        <div className="left-section">
          <div className="contact-info">
            <h2 className="contact-text">Contact Information</h2>

            {selectedContact ? (
              <div className="contact-details">
                <p><strong>{selectedContact.contact_name}</strong></p>
                <p>+63 {selectedContact.contact_phone}</p>
                <p>{selectedContact.address_info.street_address_1}, {selectedContact.address_info.street_address_2}</p>
                <p>{selectedContact.address_info.town_city}, {selectedContact.address_info.state_province}, {selectedContact.address_info.region} {selectedContact.address_info.zip_code}</p>
                <button className="change-contact-button" onClick={handleContactListModal}>
                  Change Contact Info
                </button>
              </div>
            ) : (
              <button className="add-address-button" onClick={handleAddContact}>
                + Add Address
              </button>
            )}
          </div>

          <textarea
            className="note-input"
            placeholder="Add a note (optional)"
          ></textarea>
        </div>

        <div className="right-section">
          <div className="order-summary">
            <h2>Order Summary</h2>
            <div className="order-items">
              {selectedItems.map((item: CartItem) => (
                <div className="order-item" key={item.cart_item_id}>
                  <span className="product-info">
                  {truncateText(item.product_name, 20)} {item.variation_name && `(${item.variation_name})`} x {item.quantity}
                  </span>
                  <span className="product-subtotal">{currency} {parseFloat(item.total_amount).toFixed(2)}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="order-totals">
            <div className="order-total-row">
              <span>Subtotal:</span>
              <span className="total-amount">{currency} {subtotal.toFixed(2)}</span>
            </div>
            <div className="order-total-row">
              <span>Shipping Fee:</span>
              <span className="total-amount">{currency} {shippingFee.toFixed(2)}</span>
            </div>
            <div className="order-total-row">
              <span>Service Fee (8%):</span>
              <span className="total-amount">{currency} {serviceFee.toFixed(2)}</span>
            </div>
            <br></br>
            <div className="order-total-row grand-total">
              <span>Total:</span>
              <span className="total-amount">{currency} {grandTotal.toFixed(2)}</span>
            </div>
          </div>
        
        </div>
      </div>

      <div className="checkout-footer">
        <div className="total-info">
          <p className="total-text">Total (No. of items: {selectedItems.length})</p>
          <p className="total-amount">{currency} {grandTotal.toFixed(2)}</p>
        </div>
        
        <div className="policy-checkbox">
          <input
            type="checkbox"
            id="policy-check"
            checked={isPolicyChecked}
            onChange={handlePolicyCheck}
          />
          <label htmlFor="policy-check">
            I have read and agreed to the&nbsp;
            <a href="/return-refund-policy" target="_blank" rel="noopener noreferrer">
              Return/Refund Policy
            </a>
          </label>
        </div>

        <button
          className={`place-order-button ${!isPolicyChecked ? 'disabled' : ''}`}
          disabled={!isPolicyChecked}
          onClick={handlePlaceOrder}
        >
          Place Order
        </button>
      </div>

      {isContactListModalOpen && (
        <ContactListModal
        contacts={contacts}
        onClose={() => setIsContactListModalOpen(false)}
        onSave={(selectedContact) => handleContactSave(selectedContact)}
      />
      )}

      {isAddContactModalOpen && (
        <AddContactModal
          onClose={() => setIsAddContactModalOpen(false)}
          onSave={handleContactSave} 
        />
      )}
    </div>
  );
};

export default Checkout;


//Payment Method
  {/* <div className="payment-method">
            <h2>Payment Method</h2>
            <label>
              <input type="radio" name="payment" /> Credit/Debit Card
            </label>
            <label>
              <input type="radio" name="payment" /> E-wallet
            </label>
            <label>
              <input type="radio" name="payment" disabled /> Pay On Delivery
            </label>
          </div> */}