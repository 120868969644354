import React, { useEffect, useState } from 'react';
import '../../../styles/Order.scss';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';
import { MdNavigateNext } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PageLoading from 'Pages/layout/PageLoading';

interface Media {
  media_url: string;
}

interface Item {
  product_name: string;
  quantity: number;
  media: Media[];
}

interface Order {
  id: string;
  order_reference: string;
  status: string;
  store_name: string;
  store_logo: string;
  items: Item[];
  order_total: string;
}

const Orders = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('All');

  const currency = process.env.REACT_APP_CURRENCY;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = getTokenOrDefault();
        const response = await tryFetch({
          url: '/g/orders',
          method: 'GET',
          token,
          query: '?page=1&size=10&limit=10',
        });

        if (response.is_success && Array.isArray(response.data)) {
          const transformedOrders: Order[] = response.data.map((order: any) => ({
            id: order.id,
            order_reference: order.order_reference,
            status: order.status,
            store_name: order.store_name,
            store_logo: order.store_logo,
            items: Array.isArray(order.items)
              ? order.items.map((item: any) => ({
                  product_name: item.product_name,
                  quantity: item.quantity,
                  media: Array.isArray(item.media)
                    ? item.media.map((media: any) => ({
                        media_url: media.media_url,
                      }))
                    : [],
                }))
              : [], 
            order_total: order.order_total,
          }));
          setOrders(transformedOrders);
        } else {
          console.error('Failed to fetch orders:', response.errors || response.error);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrders();
  }, []);
  
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'TO_PAY':
        return '#FF6500';
      case 'TO_SHIP':
        return '#A91079';
      case 'TO_RECEIVE':
        return '#4C3BCF';
      case 'COMPLETED':
        return '#832b99';
      case 'CANCELLED':
        return '#ff3333';
      case 'RETURN_REFUND':
        return '#3d3d3d';
      default:
        return '#000';
    }
  };

  const filteredOrders = orders.filter((order) => 
    activeTab === 'All' || order.status === activeTab
  );

  if (loading) {
    return < PageLoading/>;
  }

  return (
    <div className="order-page">
      <h2 className="order-header">Your Orders</h2>
      <div className="tabs">
        {['All', 'TO_PAY', 'TO_SHIP', 'TO_RECEIVE', 'COMPLETED', 'CANCELLED', 'RETURN_REFUND'].map((tab) => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.replace(/_/g, ' ')}
          </button>
        ))}
      </div>
      <div className="orders-container">
        {/* {orders.length > 0 ? (
          orders.map((order, index) => ( */}
          {filteredOrders.length > 0 ? (
          filteredOrders.map((order, index) => (
            <div key={index} className="order-card">
              <p className="order-id">Reference No.: {order.order_reference}</p>
              <p className="order-status"
              style={{ color: getStatusColor(order.status) }}>
                Status: {order.status.replace(/_/g, ' ')}
              </p>
              <hr className="divider" />
              <div className="merchant-details">
                <img
                  src={order.store_logo}
                  alt="Store Logo"
                  className="store-logo"
                />
                <p className="store-name">{order.store_name}</p>
              </div>
              <div className="product-list">
                {order.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="product-item">
                    <img
                      src={item.media[0]?.media_url || '/default-image.png'}
                      alt={item.product_name}
                      className="product-image"
                    />
                    <p className="product-name">{item.product_name}</p>
                  </div>
                ))}
              </div>
              <p className="item-count">
                Items: {order.items.length}
              </p>
              <p className="total-amount">
                Total Amount: {currency} {order.order_total}
              </p>
              <Link to={`/orders/${order.id}/${order.order_reference}`} className="next-icon">
                <MdNavigateNext size="28" />
              </Link>
            </div>
          ))
        ) : (
          <p className='no-order'>No orders found.</p>
        )}
      </div>
    </div>
  );
};

export default Orders;
